import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '../components/Link';
import Layout from '../components/layout/Layout'
import Button from '@material-ui/core/Button'


function ThankYou() {
    return (
        <Layout>
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Thank you for contacting us
                </Typography>
                <Typography variant="body1">
                    We will process your message and get back to you (usually within 24 hours on working days)
                </Typography>
                <Link to="/"><Button contained color="primary">Go to the main page</Button></Link>
            </Box>
        </Layout>


    );
}

export default ThankYou